body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

table tr.membernotactiveyet td {
  background-color: rgb(148, 126, 247);
}

table tr.memberused td {
  background-color: rgb(247, 212, 126);
}

table tr.memberexpired td {
  background-color: rgb(247, 126, 183);
}
